import cx from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import { Parallax } from 'react-parallax';
import Images from '../utils/Images';
import { figureImageProps, loadingType } from '../utils/propTypes';
// eslint-disable-next-line import/no-cycle
import { useIsInEditor } from './Context';
import SmartLink from './SmartLink';

export function computeCDNUrl({ maxWidth, maxHeight, src, square, type }) {
  const options = { type };
  if (maxWidth && square) return Images.square(src, maxWidth, options);
  if (maxWidth) return Images.maxWidth(src, maxWidth, options);
  if (maxHeight) return Images.maxHeight(src, maxHeight, options);
  return Images.cdn(src);
}

function getEditorIFrame() {
  // eslint-disable-next-line no-restricted-globals
  if (typeof window !== 'undefined' && window.editorIframe) {
    // eslint-disable-next-line no-restricted-globals
    return window.editorIframe.contentWindow.document;
  }
  return undefined;
}

const FigureImage = React.memo(
  ({
    alt,
    src,
    maxWidth,
    square,
    format,
    className,
    style,
    parallax,
    loading,
    imageProps,
    ctaUrl,
  }) => {
    const isInEditor = useIsInEditor();
    const requiresObjectFit = !!format;

    const isOriginalFormat = format === '';
    const url = computeCDNUrl({ src, maxWidth, square, type: get(imageProps, 'type', 'original') });
    const imageAlt = (src && src.alt) || alt;

    const render = () => {
      if (parallax === 'normal') {
        return (
          <Parallax
            bgImage={url}
            bgImageAlt={imageAlt}
            key={format}
            parent={getEditorIFrame()}
            strength={200}
          >
            <div className={cx('image', className, format || 'is-16by9')} />
          </Parallax>
        );
      }

      if (parallax === 'fullpage') {
        return (
          <div
            className={cx('parallax image', className, format || 'is-16by9')}
            style={{ backgroundImage: `url("${url}")` }}
          />
        );
      }

      return (
        <figure className={cx('image', className, format)}>
          <img
            alt={imageAlt}
            className="has-ratio"
            src={url}
            loading={loading}
            style={{
              objectFit: requiresObjectFit ? style.objectFit || 'cover' : undefined,
              width: isOriginalFormat ? 'unset' : undefined,
            }}
          />
        </figure>
      );
    };

    if (isInEditor) return render();
    return <SmartLink to={ctaUrl}>{render()}</SmartLink>;
  },
);

FigureImage.defaultProps = {
  alt: undefined,
  className: '',
  format: undefined,
  imageProps: {},
  loading: undefined,
  maxWidth: undefined,
  parallax: undefined,
  square: undefined,
  style: {},
  src: undefined,
  ctaUrl: undefined,
};

FigureImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  format: PropTypes.string,
  loading: loadingType,
  maxWidth: PropTypes.number,
  parallax: PropTypes.string,
  square: PropTypes.string,
  style: PropTypes.object,
  imageProps: figureImageProps,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ctaUrl: PropTypes.string,
};

export default FigureImage;
