import React, { Component, PureComponent } from 'react';
import BaseSection from './BaseSection';
import { withComponents } from '../components/Context';
import { Social } from '../components';

class Item extends PureComponent {
  render() {
    const {
      image,
      text,
      type = 'image',
      socialmedia = [],
      icon,
      style = {},
      components,
      onChange,
    } = this.props;
    const { Image, RichText, FAIcon } = components;
    return (
      <React.Fragment>
        {type === 'image' && <Image src={image} className="list-section__image" style={style} />}
        {type === 'text' && (
          <RichText html={text} name="text" placeholder="Ajouter du texte" style={style} />
        )}
        {type === 'icon-text' && (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <FAIcon icon={icon} className="list-section__icon" />
            <RichText html={text} name="text" placeholder="Ajouter du texte" style={style} />
          </div>
        )}
        {type === 'social' && (
          <Social socialmedia={socialmedia} className="list-section__social" onChange={onChange} />
        )}
      </React.Fragment>
    );
  }
}

export const ListItem = withComponents(Item);

class ListSection extends Component {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    gridSize: 4,
    centerVertically: true,
  };

  renderItem = (item) => {
    return <ListItem {...item} />;
  };

  render() {
    const { components, gridSize, title, itemList, isCentered, centerVertically, ...props } =
      this.props;
    const { Grid } = components;
    return (
      <BaseSection {...props} title={title} block={this.props}>
        <Grid
          items={itemList.items}
          name="itemList.items"
          isList
          isCentered={isCentered}
          centerVertically={centerVertically}
          gridSize={gridSize}
          tabletGridSize={Math.round(gridSize / 2)}
          render={this.renderItem}
        />
      </BaseSection>
    );
  }
}

export default withComponents(ListSection);
