import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cx from 'classnames';
import { withComponents } from '../components/Context';
import BaseSection from './BaseSection';
import './ImageSection.scss';

class ImageSection extends PureComponent {
  render() {
    const { image, components, size, ...props } = this.props;
    const { FigureImage } = components;

    return (
      <BaseSection {...props} block={this.props} hasHeader={false}>
        <div className={cx('image-section', `image-section--${size}`)}>
          <FigureImage src={image} />
        </div>
      </BaseSection>
    );
  }
}

ImageSection.defaultProps = {
  size: 'md',
};

ImageSection.propTypes = {
  components: PropTypes.object.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  backgroundColor: PropTypes.string,
};

export default withComponents(ImageSection);
