import cx from 'classnames';
import noop from 'lodash/noop';
import trimEnd from 'lodash/trimEnd';
import PropTypes from 'prop-types';
import React, { PureComponent, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import scrollIntoView from 'scroll-into-view-if-needed';
import { AppContext, EditContext, withAppContext, withEdit } from '../Context';
import Images from '../utils/Images';
import { smoothScroll } from '../utils/domUtils';
import { getCurrentPath } from '../utils/pages';
import { appDataProps, iconProps, languageProps } from '../utils/propTypes';
import { slugify, stripHtml, titleFromHtml } from '../utils/stringUtils';
import MenuDisconnect from './Connection/MenuDisconnect';
import { withComponents } from './Context';
import FAIcon from './FAIcon';
import HeaderMenu from './HeaderMenu';
import NavLinkWithParams from './NavLinkWithParams';
import { extractColorLuma } from '../styles/colors';

export function extractPageMenuSections(page) {
  return (
    page.sections
      ?.filter((section) => section.showInMenu?.enabled)
      ?.map((section) => ({
        _id: section._id,
        title: titleFromHtml(section.title),
        link: `${page.link}#${slugify(stripHtml(section.title))}`,
        type: 'page',
      })) || []
  );
}

export const MenuLanguages = withRouter(
  ({ location, language, languages = [], menu, onLanguageChange }) => {
    const { rooturl, site } = useContext(AppContext);
    const { buttonType = 'classic' } = menu;
    const navMenuItemClass = `navbar-item--${buttonType}`;
    if (!(languages?.length > 1)) return null;

    function handleClick(e, lang) {
      if (process.env.REACT_APP_BUILD_TARGET === 'editor') {
        e.preventDefault();
        e.stopPropagation();
        onLanguageChange(lang.name);
      }
    }

    const currentLang = language || languages[0]?.name;

    const loc = trimEnd(location.pathname, '/');
    const activePage = site.pages.find((p) => trimEnd(p.link, '/') === loc) || site.pages[0];

    function buildUrl(lang) {
      return getCurrentPath(rooturl, activePage, { name: lang });
    }

    return (
      <div
        className={cx('navbar-item navbar-item--languages', navMenuItemClass)}
        style={{ fontSize: 15 }}
      >
        <span>
          {languages.map((lang) => (
            <>
              <span
                className={cx('navbar-item__language', currentLang === lang.name && 'is-active')}
              >
                <a href={buildUrl(lang.name)} onClick={(e) => handleClick(e, lang)}>
                  {lang.label}
                </a>
              </span>
            </>
          ))}
        </span>
      </div>
    );
  },
);

export const MenuItem = ({ item, menu, onCloseBurgerMenu }) => {
  const { title, icon, link, type = 'page', menuButton = {} } = item;
  const { showIcons = false, buttonType = 'classic' } = menu;
  const { type: pageButtonType, variant } = menuButton;
  const navMenuItemClass = `navbar-item--${pageButtonType || buttonType}`;
  const navVariantClass = pageButtonType ? `navbar-item--${variant}` : '';

  const isExternalLink = type === 'link' || link.indexOf('http') === 0;

  // eslint-disable-next-line react/prop-types
  const Component = ({ children, ...rest }) => {
    if (isExternalLink) {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onCloseBurgerMenu}
          {...rest}
        >
          {children}
        </a>
      );
    }
    if (typeof link === 'string' && link.indexOf('#') !== -1) {
      // Auto-inject private key if needed
      const to =
        // eslint-disable-next-line no-restricted-globals
        link.indexOf('?') === -1 ? link.replace('#', `${window.location.search || ''}#`) : link;
      return (
        <HashLink to={to} className={rest.className} style={rest.style} scroll={smoothScroll}>
          {children}
        </HashLink>
      );
    }
    return (
      <NavLinkWithParams to={link} exact {...rest} onClick={onCloseBurgerMenu}>
        {children}
      </NavLinkWithParams>
    );
  };
  return (
    <Component
      className={cx('navbar-item', navMenuItemClass, navVariantClass)}
      activeClassName="is-active"
      style={{ fontSize: 15 }}
    >
      {showIcons && (
        <span className="icon">
          <FAIcon icon={icon} />
        </span>
      )}
      <span>{title}</span>
    </Component>
  );
};

MenuItem.defaultProps = {};

MenuItem.propTypes = {
  onCloseBurgerMenu: PropTypes.func.isRequired,
  item: PropTypes.shape({
    title: PropTypes.string,
    icon: iconProps,
    link: PropTypes.string,
    type: PropTypes.oneOf(['link', 'page']),
    menuButton: PropTypes.shape({
      type: PropTypes.oneOf(['classic', 'tab', 'button']),
    }),
    buttonClassName: PropTypes.string,
  }).isRequired,
  menu: PropTypes.shape({
    showIcons: PropTypes.bool,
    buttonType: PropTypes.oneOf(['classic', 'tab', 'button']),
  }).isRequired,
};

class Menu extends PureComponent {
  state = {
    isTop: true,
    isActive: false,
  };

  componentDidMount() {
    // eslint-disable-next-line no-restricted-globals
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-restricted-globals
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleToggleBurgerMenu = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  closeBurgerMenu = () => {
    this.setState({ isActive: false });
  };

  handleScrollIntoView = (title) => {
    // eslint-disable-next-line no-restricted-globals
    const node = document.getElementById(title);
    scrollIntoView(node, {
      behavior: 'smooth',
      block: 'start',
      inline: 'center',
    });
  };

  handleScroll = () => {
    // eslint-disable-next-line no-restricted-globals
    const isTopWindow = window.scrollY === 0;
    const { isTop } = this.state;
    if (isTopWindow !== isTop) {
      this.setState({ isTop });
    }
  };

  handleLogoChange = (key, value) => {
    const { onChange } = this.props;
    onChange('logo', key, value ? value.uri : '');
  };

  handleMenuChange = (key, value) => {
    const { onChange } = this.props;
    onChange('menu', key, value);
  };

  enhanceURL = (url) => {
    const { data } = this.props;
    const { user } = data;
    if (user && user._id) {
      const id = user._id;
      if (url.indexOf('?') !== -1) {
        // Add to end
        return `${url}&u=${id}`;
      }
      return `${url}?u=${id}`;
    }
    return url;
  };

  render() {
    const {
      logo,
      logoProps,
      pages,
      fixed,
      components,
      menu,
      languages,
      forceShowPages,
      isEditing,
      language,
      onLanguageChange,
      design,
    } = this.props;
    const { Image, RichText } = components;
    const {
      showPages = true,
      showLanguages = false,
      backgroundColor,
      invertColors,
      showHeaderMenu = false,
      headerMenuSettings = {},
    } = menu;
    const { isActive, isTop } = this.state;
    const active = isActive ? 'is-active' : '';
    const headerMenuShown = showHeaderMenu ? 'header-menu-is-shown' : '';

    // Only show if more than 1 page, or some sections should be shown in menu
    const mustShowPages =
      forceShowPages ||
      (showPages && pages.filter((p) => p.type !== 'subpage').length > 1) ||
      pages.filter((p) => p.sections?.find((s) => s.showInMenu?.enabled));
    const hasTranslations = menu.translations && menu.translations.length > 0;
    const headerColor = design.primaryColor || backgroundColor;
    const isDarkColor = !invertColors && !!headerColor && extractColorLuma(headerColor) < 140;
    return (
      <EditContext.Provider value={{ language, onChange: this.handleLogoChange }}>
        <nav
          id="navbar"
          className={cx('navbar', {
            'is-fixed-top': fixed,
            'is-at-top': isTop,
            invert: invertColors,
            'is-dark-navbar': isDarkColor,
          })}
          style={{ backgroundColor }}
        >
          <div className="container">
            <div className="navbar-brand" style={{ width: mustShowPages ? 'auto' : '100%' }}>
              <NavLinkWithParams to="/" disabled={isEditing} aria-label="Homepage">
                {(logo || isEditing) && (
                  <Image
                    src={logo}
                    name="logo"
                    alt={`${menu.menuTitle || 'Event'} logo`}
                    isUnsplash={false}
                    {...logoProps}
                  />
                )}
              </NavLinkWithParams>
              <EditContext.Provider value={{ language, onChange: this.handleMenuChange }}>
                <RichText
                  html={menu.menuTitle || ''}
                  name="menuTitle"
                  placeholder="Add title"
                  className=" menuTitle"
                  style={{ width: mustShowPages || hasTranslations ? 'auto' : '100%' }}
                />
              </EditContext.Provider>
              {mustShowPages && (
                <span
                  aria-label="menu"
                  aria-expanded="false"
                  className={cx('navbar-burger', active)}
                  onClick={this.handleToggleBurgerMenu}
                >
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                  <span aria-hidden="true" />
                </span>
              )}
            </div>
            {mustShowPages && (
              <div className={cx('navbar-menu', active, headerMenuShown)}>
                <div className="navbar-end">
                  {pages
                    .filter((p) => p.type !== 'subpage' && p.type !== 'hidden')
                    .map((page) => (
                      <React.Fragment key={page._id}>
                        <MenuItem
                          item={page}
                          menu={menu}
                          onCloseBurgerMenu={this.closeBurgerMenu}
                        />
                        {extractPageMenuSections(page).map((item) => (
                          <MenuItem
                            key={`${page.link}-${item._id}`}
                            item={item}
                            menu={menu}
                            onCloseBurgerMenu={this.closeBurgerMenu}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  {showLanguages && (
                    <MenuLanguages
                      language={language}
                      languages={languages}
                      menu={menu}
                      onLanguageChange={onLanguageChange}
                    />
                  )}
                  <MenuDisconnect />
                </div>
                {showHeaderMenu && (
                  <HeaderMenu
                    language={language}
                    languages={languages}
                    invertColors={invertColors}
                    settings={headerMenuSettings}
                    onLanguageChange={onLanguageChange}
                  />
                )}
              </div>
            )}
            {hasTranslations && (
              <div className="navbar-end">
                <div className="navbar-item navbar-item--flags">
                  {menu.translations.map((trans, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <a href={this.enhanceURL(trans.url)} key={index}>
                      <img src={Images.maxWidth(trans.image, 300)} alt={`flag-${trans.lang}`} />
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        </nav>
      </EditContext.Provider>
    );
  }
}

Menu.defaultProps = {
  fixed: true,
  forceShowPages: false,
  isEditing: false,
  language: undefined,
  languages: [],
  logo: undefined,
  logoProps: undefined,
  menu: {},
  onChange: noop,
  pages: [],
};

Menu.propTypes = {
  data: appDataProps.isRequired,
  components: PropTypes.object.isRequired,
  fixed: PropTypes.bool,
  forceShowPages: PropTypes.bool,
  isEditing: PropTypes.bool,
  language: PropTypes.string,
  languages: PropTypes.arrayOf(languageProps),
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  logoProps: PropTypes.shape({ maxWidth: PropTypes.number, maxHeight: PropTypes.number }),
  menu: PropTypes.object,
  onChange: PropTypes.func,
  pages: PropTypes.array,
  onLanguageChange: PropTypes.func.isRequired,
};

export default withAppContext(withEdit(withRouter(withComponents(Menu))));
