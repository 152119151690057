import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type SmartLinkProps = {
  to: string;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};
const SmartLink: FC<SmartLinkProps> = ({ to, children, className, style }) => {
  if (!to) return <>{children}</>;
  if (to.startsWith('http'))
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" className={className} style={style}>
        {children}
      </a>
    );
  return (
    <Link to={to} className={className} style={style}>
      {children}
    </Link>
  );
};

export default SmartLink;
