/* eslint-disable react/button-has-type */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Social } from '../components';
import { withComponents } from '../components/Context';
import { withConfigs, withEdit, withDesign, EditContext } from '../Context';
import { ListItem } from './ListSection';
import SocialNetworksModal from '../components/SocialNetworkModal';
import { languageProps } from '../utils/propTypes';

class Footer extends PureComponent {
  state = {
    editSocial: false,
  };

  handleChange = (key, value) => {
    const { _id, onChange } = this.props;
    onChange(_id, key, value);
  };

  showSocialNetworks = () => this.setState({ editSocial: true });

  hideSocialNetworks = () => this.setState({ editSocial: false });

  renderBlock = (block, index) => {
    const { isEditing, components } = this.props;
    const { editSocial } = this.state;
    const { RichText, Grid } = components;
    switch (block.type) {
      case 'List': {
        // Use directly ListSection ??
        const { gridSize = 4, backgroundColor = 'white' } = block;
        return (
          <section key={block._id} className="section--ListSection" style={{ backgroundColor }}>
            <Grid
              items={block.itemList.items || []}
              name={`blocks[${index}].itemList.items`}
              isList
              isCentered
              centerVertically
              gridSize={gridSize}
              tabletGridSize={Math.round(gridSize / 2)}
              render={(item) => <ListItem {...item} />}
            />
          </section>
        );
      }
      case 'Social':
        if (isEditing) {
          return (
            <div key={index}>
              <button
                className="button dark is-small"
                onClick={this.showSocialNetworks}
                style={{ position: 'absolute', right: 60 }}
                aria-label="Edit social media"
              >
                <i className=" fa fa-cog" />
              </button>
              <Social {...block} />
              {editSocial && (
                <SocialNetworksModal
                  onClose={this.hideSocialNetworks}
                  socialmedia={block.socialmedia}
                  name={`blocks[${index}].socialmedia`}
                  onChange={this.handleChange}
                />
              )}
            </div>
          );
        }
        return <Social key={index} {...block} />;
      case 'Text':
        return (
          <RichText
            key={index}
            html={block.text}
            namePrefix={`blocks[${index}]`}
            name="text"
            placeholder="Footer text"
            style={{ color: 'white', padding: 10 }}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { language, languages, style = {}, children, blocks = [] } = this.props;
    return (
      <footer className="footer" style={{ ...style }}>
        <div className="content has-text-centered" style={{ margin: 0 }}>
          {children}
          <EditContext.Provider value={{ language, languages, onChange: this.handleChange }}>
            {blocks.map(this.renderBlock)}
          </EditContext.Provider>
        </div>
      </footer>
    );
  }
}

Footer.defaultProps = {
  blocks: [],
  children: undefined,
  isEditing: false,
  language: undefined,
  languages: undefined,
  style: {},
};

Footer.propTypes = {
  _id: PropTypes.string.isRequired,
  blocks: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string, type: PropTypes.string })),
  components: PropTypes.object.isRequired,
  children: PropTypes.node,
  isEditing: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  language: PropTypes.string, // Current language
  languages: PropTypes.arrayOf(languageProps),
  style: PropTypes.object,
};

export default withConfigs(withEdit(withDesign(withComponents(Footer))));
