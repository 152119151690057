import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withEdit } from '../Context';
import SocialNetworksModal from './SocialNetworkModal';
import ShareCurrentPageButton from './Share/ShareCurrentPageButton';
import { computeFaIconClassName } from '../utils/iconUtils';

export const SocialIcon = ({ icon, url, color, className }) => {
  if (!url) return null;
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" aria-label={icon} style={{ margin: 5 }}>
      <i className={cx(`${computeFaIconClassName(icon)} fa-2x`, className)} style={{ color }} />
    </a>
  );
};

SocialIcon.defaultProps = {
  className: '',
  color: undefined,
  icon: undefined,
  url: undefined,
};

SocialIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  url: PropTypes.string,
};

class Social extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    const { isOpen } = this.state;
    const {
      socialmedia,
      className,
      isEditing,
      namePrefix,
      onChange,
      disableEdit,
      language,
      invertColors,
    } = this.props;
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {socialmedia.map((social, index) => (
            <SocialIcon {...social} key={social.name || index} className={className} />
          ))}
          <ShareCurrentPageButton
            socialMedias={socialmedia}
            iconClassName={className}
            language={language}
            invertColors={invertColors}
          />
        </div>
        {!disableEdit && isEditing && (
          <div>
            <button
              type="button"
              className="button dark is-small"
              onClick={() => this.setState({ isOpen: true })}
              aria-label="Edit social media"
            >
              <i className=" fa fa-cog" />
            </button>
            {isOpen && (
              <SocialNetworksModal
                onClose={() => this.setState({ isOpen: false })}
                socialmedia={socialmedia}
                name={`${namePrefix}.socialmedia`}
                onChange={onChange}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

Social.defaultProps = {
  className: '',
  language: 'fr',
  disableEdit: false,
  namePrefix: undefined,
  socialmedia: [],
  isEditing: false,
  invertColors: false,
};
Social.propTypes = {
  className: PropTypes.string,
  language: PropTypes.string,
  disableEdit: PropTypes.bool,
  isEditing: PropTypes.bool,
  namePrefix: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  socialmedia: PropTypes.array,
  invertColors: PropTypes.bool,
};

export default withEdit(Social);
