/* eslint-disable operator-linebreak */
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import noop from 'lodash/noop';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { withComponents } from './Context';
import { providePrefix, withEdit } from '../Context';
import NavLinkWithParams from './NavLinkWithParams';

const imageFormats = {
  rectangular: '3/2',
  square: '1/1',
};

class Partner extends PureComponent {
  handleChange = (e) => {
    const { namePrefix, onChange } = this.props;
    const { name, value } = e.target;
    if (namePrefix) {
      onChange(`${namePrefix}.${name}`, value);
    } else {
      onChange(name, value);
    }
  };

  render() {
    const { title, template, image, url, components, isEditing, imageFormat } = this.props;
    const { Image, RichText } = components;
    const isExternalLink = !isEditing && url && url.indexOf('http') === 0;

    const imageProps = {
      src: image,
      maxWidth: 1000,
      style:
        imageFormat && imageFormat !== 'original'
          ? {
              aspectRatio:
                imageFormat === 'rectangular' ? imageFormats.rectangular : imageFormats.square,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              objectFit: 'contain',
            }
          : {},
    };

    return (
      <div className={`c-item--partner ${template ? `c-item--partner--${template}` : ''}`}>
        {!isEditing && url ? (
          <>
            {isExternalLink ? (
              <a href={url} target="_blank" rel="noopener noreferrer">
                <Image {...imageProps} />
              </a>
            ) : (
              <NavLinkWithParams to={url} exact>
                <Image {...imageProps} />
              </NavLinkWithParams>
            )}
          </>
        ) : (
          <Image {...imageProps} />
        )}
        <RichText
          className="title"
          name="title"
          html={title}
          placeholder="Add title"
          style={{ textAlign: 'center' }}
        />
        {isEditing && (
          <input
            type="text"
            value={url}
            name="url"
            placeholder="URL"
            onChange={this.handleChange}
            style={{ width: '100%' }}
          />
        )}
      </div>
    );
  }
}

Partner.defaultProps = {
  isEditing: false,
  image: {},
  namePrefix: '',
  onChange: noop,
  template: '',
  title: '',
  url: '',
  imageFormat: 'original',
};

Partner.propTypes = {
  components: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  image: PropTypes.object,
  namePrefix: PropTypes.string,
  onChange: PropTypes.func,
  template: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  imageFormat: PropTypes.string,
};

const PartnerItem = withEdit(withComponents(Partner));

class Partners extends PureComponent {
  renderItem = (item) => {
    const { isEditing, template, imageFormat } = this.props;
    return (
      <PartnerItem template={template} imageFormat={imageFormat} {...item} isEditing={isEditing} />
    );
  };

  render() {
    const { partners, gridSize, components, carouselOptions, template, isEditing } = this.props;
    const { Grid } = components;

    const responsive = {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: gridSize,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: gridSize,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: Math.floor(gridSize / 2),
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };

    return template === 'carousel' ? (
      <Carousel
        key={JSON.stringify(responsive)}
        responsive={responsive}
        draggable={false}
        infinite
        autoPlay={carouselOptions.auto}
        autoPlaySpeed={3000}
        swipeable
        keyBoardControl={!carouselOptions.auto}
        arrows={!carouselOptions.auto}
        renderDotsOutside
      >
        {partners.map((partner, index) => (
          <div key={index} style={{ margin: '0 10px' }}>
            {isEditing
              ? providePrefix(`itemList.items[${index}]`, this.renderItem(partner))
              : this.renderItem(partner)}
          </div>
        ))}
      </Carousel>
    ) : (
      <Grid
        items={partners}
        name="itemList.items"
        gridSize={gridSize}
        centerVertically
        isCentered
        tabletGridSize={Math.floor(gridSize / 2)}
        render={this.renderItem}
      />
    );
  }
}

Partners.defaultProps = {
  gridSize: 4,
  isEditing: false,
  partners: [],
  template: '',
  carouselOptions: { auto: false },
  imageFormat: 'original',
};

Partners.propTypes = {
  components: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  gridSize: PropTypes.number,
  partners: PropTypes.array,
  template: PropTypes.string,
  imageFormat: PropTypes.string,
  carouselOptions: PropTypes.shape({
    auto: PropTypes.bool,
  }),
};

export default withComponents(Partners);
